<template>
  <div class="container-fluid p-0">
    <div class="row no-gutters">
      <div class="col-sm-12 text-center">
        <div
          class="iq-error"
          :class="$route.params.code !== '404' ? 'error-500' : ''"
        >
          <img
            :src="require('@/assets/images/error/error-01.png')"
            class="img-fluid iq-error-img"
          >
          <h2 class="mb-0">
            Oops! This Page is Not Found.
          </h2>
          <p>The requested page dose not exist.</p>
          <button
            class="btn btn-success mt-3"
            @click="$router.go(-1)"
          >
            <i class="ri-home-4-line" />Back to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  name: 'ErrorPage',
  mounted () {
    core.index()
  }
}
</script>
